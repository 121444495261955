<template>
  <div>

    <el-row>
      <h3>{{ $t("title.orderinfo") }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" :rules="rules">
        <el-col :span="10">
          <el-form-item :label="$t('topic.PlatformOrderNumber')" prop="platFormName">
            <el-input v-model.trim="creatermaForm.orderCode" :placeholder="$t('title.Afterentering')" @keyup.enter.native="pickOrdersearch()" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.platform')" prop="platFormName">
            <el-input v-model="creatermaForm.platFormName " disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.site')" prop="siteCode">
            <el-input v-model="creatermaForm.siteCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.shopName')" prop="shopCode">
            <el-input v-model="creatermaForm.shopCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.BuyerID')" prop="buyerUsername">
            <el-input v-model="creatermaForm.buyerUsername" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAType')" prop="returnDetailType">
            <el-radio-group v-model="creatermaForm.returnDetailType">
              <el-radio label="0">{{ $t('topic.Refunds') }}</el-radio>
              <el-radio label="1">{{ $t('topic.OnlyRefund') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAWay')" prop="returnType">
            <el-radio-group v-model="creatermaForm.returnType">
              <el-radio label="0">{{ $t('topic.System') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Manual') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Refundinformation') }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" :rules="rules">
        <el-col :span="20">
          <el-form-item :label="$t('topic.Typerefund')" prop="refundType">
            <el-radio-group v-model="creatermaForm.refundType" @change="changeRadio">
              <el-radio v-if="creatermaForm.returnDetailType == 0" label="0">{{ $t('topic.Agreeandrefund') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Fullrefund') }}</el-radio>
              <el-radio label="2">{{ $t('topic.Partialrefund') }}</el-radio>
              <el-radio label="3">{{ $t('topic.Refuse') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Productinformation') }}</h3>
      <template>
        <el-table
          ref="multipleTable"
          :data="detailList.detailList"
          default-expand-all
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
          />
          <el-table-column
            :label="$t('title.Refundinformation')"
            align="center"
          >
            <template slot-scope="props">
              <div style="display:flex">
                <el-image
                  :src="props.row.mainImage"
                  style="width: 45px; height: 45px; vertical-align: middle"
                  class="mr-3"
                />
                <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
              </div></template>
          </el-table-column>
          <el-table-column
            prop="returnNumber"
            :label="$t('topic.Numberofrefunds')"
            width="100"
            align="center"
          />
          <el-table-column
            :label="$t('topic.reasonforreturn')"
            width="200"
            align="center"
          >
            <template slot-scope="props">
              <el-select
                v-model="props.row.returnReasonCode"
                clearable
                filterable
                disabled
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in returnReasonOptions"
                  :key="item.returnReasonCodeEN"
                  :label="language === 'en-US' ? item.returnReasonCodeEN : item.returnReasonCodeCN"
                  :value="item.returnReasonCodeEN"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('title.orderamount')"
            width="150"
          >
            <template slot-scope="props">
              <p style="display:flex;align-items: center;"><span> $ </span> <span>{{ props.row.itemPrice }}</span></p>
            </template>
          </el-table-column>

        </el-table>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" style="margin-top:10px">
          <el-col :span="15">
            <el-form-item :label="$t('title.Buyerpessage')">
              <el-input
                v-model="creatermaForm.sellComments"
                type="textarea"
                :rows="2"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('title.stytemNote')">
              <el-input
                v-model="creatermaForm.remark"
                type="textarea"
                :rows="2"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Buyerpicture')">
              <el-upload
                ref="upload"
                :action="action"
                list-type="picture-card"
                :file-list="sellerFileList"
                :before-remove="beforeRemoved"
                :on-preview="handlePictureCardPreview"
                :headers="uploadHeaders"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :class="$i18n.locale" :label="$t('title.Attachpicture')">
              <el-upload
                :action="action"
                :headers="uploadHeaders"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview1"
                :before-remove="beforeRemoved1"
                :on-remove="handleRemove1"
                :on-success="handleSuccess1"
                :file-list="brcfilelist"
              >

                <i class="el-icon-upload" />
              </el-upload>
              <el-dialog :visible.sync="imgdialogVisible">
                <img width="100%" :src="dialogImageUrl1" alt="">
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-form>
      </template>

    </el-row>
    <el-row :span="24" type="flex" style="margin-top: 20px" justify="end">
      <el-col :span="3">
        <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" :loading="refundLoding" @click="handleReview">{{ $t('title.Release') }}</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { getAllReasonCode, updateStatus, getDetailReturnsInfoById } from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'
export default {
  mixins: [mixin],
  data() {
    return {
      language: '',
      disabled: true,
      dialogImageUrl: '',
      dialogImageUrl1: '',
      sellerFileList: [],
      brcfilelist: [],
      buyLableDialogVisible: false,
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      sellerImageUrl: [],
      imageUrl: [],
      action: process.env.VUE_APP_UPLOAD_API,
      imgdialogVisible: false,
      detailList: {},
      returnReasonCodeOptions: [],
      rmaId: '',
      flag: true,
      warehouseOptions: [],
      creatermaForm: {
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        returnDetailType: '',
        returnType: '',
        refundType: ''

      },
      ebayForm: {
        ebayLable: '0'
      },
      dateList: [], // 存放选中项数据
      returnReasonOptions: [], // 接收退货信息原因
      reviewLoding: false, // 确认审核提交loading 防止重复提交
      trackingData: '', // 接收买标后返回的物流单号trackingNumber
      rules: {
        orderCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        shopCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platFormName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        buyerUsername: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        returnDetailType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        refundType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        returnReasonCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      }
    }
  },
  mounted() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this._getDetailReturnsInfoById()
  },
  methods: {
    changeRadio(val) {
      this.$forceUpdate()
    },
    // 选中明细数据val
    handleSelectionChange(val) {
      this.dateList = val
    },
    // 通过id获取数据
    async _getDetailReturnsInfoById(query) {
      const { id } = this.$route.query
      const { datas } = await getDetailReturnsInfoById(id)
      this.detailList = datas
      const { rmaId, returnDetailType, shopCode, orderCode, returnType, platFormName, siteCode, sellComments, buyerUsername } = this.detailList
      this.creatermaForm = { rmaId, shopCode, returnDetailType: String(returnDetailType), orderCode, returnType: String(returnType), platFormName, siteCode, sellComments, buyerUsername }
      // 获取
      this.detailList.detailList.map(e => {
        this.creatermaForm.refundType = String(e.refundType)
        // this.$set(this.creatermaForm, this.creatermaForm.refundType, e.refundType)
        console.log(this.creatermaForm.refundType)
      })
      this.$nextTick(() => {
        this.detailList.detailList.forEach(i => {
          this.$refs['multipleTable'].toggleRowSelection(i, true)
        })
      })
      this.detailList.imageList.map(e => {
        if (e.imageType === 0) {
          const index = e.imageUrl.lastIndexOf('\/')
          const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
          this.sellerFileList.push({
            name: fileName,
            url: e.imageUrl
          })
        }
      })
      this._getAllReasonCode(this.creatermaForm.platFormName)
    },
    // 确认审核
    handleReview() {
      if (this.creatermaForm.refundType === 2 || this.creatermaForm.refundType === '2') {
        sessionStorage.setItem('indatas', JSON.stringify(this.detailList))
        this.$router.push({
          path: '../ebay-refunds',
          append: true,
          query: { 'repostType': 5, ...this.userInfo }
        })
        return
      } else {
        const list = []
        this.detailList.operatorId = 0
        list.push(this.detailList)
        this._updateStatus(list)
      }
    },
    // 更新状态
    async _updateStatus(data) {
      try {
        this.reviewLoding = true
        const { code, msg } = await updateStatus(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.buyLableDialogVisible = false
          this.ShipDialogVisible = false
          this.$router.push({
            path: '../../ebay-return-manage',
            append: true,
            query: { ...this.userInfo }
          })
        }
      } finally {
        this.reviewLoding = false
      }
    },

    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas
    },
    // 返回主列表页
    goBack() {
      this.$router.push({
        path: '../../ebay-return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview(file) {
      this.ruleForm.siteImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.sellerFileList.push({
        name: filelist.name,
        url: files.datas
      })
    },
    // 移除图片之前的方法
    beforeRemoved(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 移除图片之前的方法
    beforeRemoved1(file, filelist) {
      return this.$confirm(`${this.$t('page.SureDelete')} ${file.name}？`)
    },
    // 点击已上传的图片时的方法 看大图
    handlePictureCardPreview1(file) {
      this.ruleForm.siteImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传图片成功时的方法
    handleSuccess1(files, filelist) {
      // 将上传成功的图片放到提交给后台的参数中
      this.brcfilelist.push({
        name: filelist.name,
        url: files.datas
      })
    },
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.sellerFileList = fileList
      }
    },
    handleRemove1(file, fileList) {
      // this.hideUpload1 = fileList.length >= this.limitCount1
      if (file.status === 'success') {
        if (file.status === 'success') {
          this.brcfilelist = fileList
        }
      }
    },

    changeInput(index) {
      var data = this.detailList[index]
      this.$set(this.detailList, index, data)
    },
    handlecChangeInput(index) {
      var data = this.orderData.itemList[index]
      this.$set(this.orderData.itemList, index, data)
    },
    changeNumber(index) {
      var data = this.detailList[index]
      // 订单金额不能大于原本金额
      if (parseFloat(data.itemPrice) > data.myItemPrice) {
        this.$confirm(this.$t('tips.EnterOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemPrice = data.myItemPrice
        return
      }
      if (parseFloat(data.otherRefund) > 20) {
        this.$confirm(this.$t('tips.LesserThan20'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.otherRefund = 0
        return
      }
      // 计算总金额
      data.refundAmount = (parseFloat(data.itemPrice) - Math.abs(parseFloat(data.promotionDiscount)) + parseFloat(data.otherRefund) - parseFloat(data.shippingPrice)).toFixed(2)
      this.$set(this.detailList, index, data)
    },
    changNumbers(index) {
      var data = this.orderData.itemList[index]
      // 订单金额不能大于原本金额
      if (Number(data.itemQuantity) > data.MyitemQuantity) {
        this.$confirm(this.$t('tips.ValueLesser'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      console.log(Number(data.itemQuantity) <= 0)
      if (Number(data.itemQuantity) <= 0) {
        this.$confirm(this.$t('tips.GreaterThan0'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        })
        data.itemQuantity = data.MyitemQuantity
        return
      }
      this.$set(this.orderData.itemList, index, data)
    }

  }
}
</script>

<style scoped lang="scss">
/deep/ .el-upload--picture-card {
    width: 75px;
    height: 75px;
    cursor: pointer;
    line-height:75px;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
